import { styled } from "styled-components";

export const HeaderContainer = styled.header`
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  height: 75px;

  @media (max-width: 900px) {
    justify-content: unset;
  }

`;

export const LogoContainer = styled.div`
  font-size: 24px;
  font-weight: bold;
  display: flex;
`;

export const Nav = styled.nav<{ isopen: boolean }>`
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    display: ${({ isopen }) => (isopen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 75px;
    color: #B02073;
    background-color: white;
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const NavLink = styled.a`
  color: black;
  text-decoration: none;
  margin: 0 10px;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 900px) {
    padding: 10px;
  }
`;

export const ButtonContainer = styled.div`
 
`;

export const Button = styled.button`
  background-color: #B02073;
  border: none;
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  margin-left: 10px;
  font-weight: 700;
  cursor: pointer;
`;

export const KebabMenuIcon = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 5%;
  }
`;

export const MenuIcon = styled.span`
  background-color: #B02073;
  display: block;
  width: 30px;
  height: 3px;
  margin: 5px auto;
  transition: 0.4s;
`;