import React from 'react';
import Chain from '../../../../assets/chainPng.png';
import { SubTitle, Title, TitleHelper } from '../../../../common/Styles/Styles.styles';
import { AboutYVM } from '../../../../../utils/Constants';
import { Section, Content, ImageWrapper, Text, Image } from './YVMSection.styles';

const YVMSection: React.FC = () => {

    return (
        <Section>
            <Title><TitleHelper>P2P Transfers, Global Transactions</TitleHelper></Title>
            <SubTitle style={{textAlign: 'center'}}>
                {/* YVM (YatriPay's Virtual Machine ) */}
                <SubTitle style={{textAlign: 'center'}}>Instant peer to peer transactions across the globe with Zero Fee</SubTitle>
            </SubTitle>
            <Content>
                <ImageWrapper>
                    <Image src={Chain} alt="chain" />
                </ImageWrapper>
                <Text>
                   {AboutYVM}
                </Text>
            </Content>
        </Section>
    );
};

export default YVMSection;
