import React from 'react';
import { SubTitle, Title, TitleHelper } from '../../../../common/Styles/Styles.styles';
import Mock from '../../../../assets/mock-app-2.png';
import { PlayStoreLink } from '../../../../../utils/ServerConfigs';
import { Section, LeftAlignedContent, Button, RightAlignedContent, ResponsiveButton, Image, ImageContainer } from './TransactionSection.styles';
import PlayStoreIcon from '../../../../assets/play-store.png';

const TransactionSection: React.FC = () => {
  return (
    <Section>
      <LeftAlignedContent>
        <Title>YatriPay: Beyond UPI with <TitleHelper>Lightning-Fast Transactions</TitleHelper></Title>
        <SubTitle>Send, Receive, Book and Swap. All at one place!</SubTitle>
        <Button href={PlayStoreLink} target='_blank' ><ImageContainer src={PlayStoreIcon}></ImageContainer>Download the App</Button>
      </LeftAlignedContent>
      <RightAlignedContent>
        <Image src={Mock} alt="Explore" />
      </RightAlignedContent>
      <ResponsiveButton href={PlayStoreLink} target='_blank' ><ImageContainer src={PlayStoreIcon}></ImageContainer>Download the App</ResponsiveButton>
    </Section>
  );
};

export default TransactionSection;
