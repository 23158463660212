import React, { useState } from 'react';
import Logo from '../../assets/tlogo.svg';
import { HeaderContainer, LogoContainer, KebabMenuIcon, MenuIcon, Nav, NavLink, Button, ButtonContainer } from './Header.styles';
import { ExplorerLink, LoginLink, WhitePaperPdfLink } from '../../../utils/ServerConfigs';

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogin = () => {
    window.open(LoginLink, '_blank');
  }

  const handleWhitePaper = () => {

    const anchor = document.createElement('a');
    anchor.href = WhitePaperPdfLink;
    anchor.target = '_blank';
    anchor.download = 'whitepaper.pdf'; // Change the filename as needed
    anchor.click();

    // Clean up: remove the anchor element
    anchor.remove();
    
  }

  const handleLink = () => {
  }

  return (
    <HeaderContainer>
      <LogoContainer>
        <img height={120} width={120} src={Logo} alt='logo'/>
      </LogoContainer>
      <KebabMenuIcon onClick={toggleMenu}>
        <MenuIcon />
        <MenuIcon />
        <MenuIcon />
      </KebabMenuIcon>
      <Nav isopen={menuOpen}>
        <NavLink href="#about" onClick={handleLink}>About us</NavLink>
        {/* <NavLink href="#bar">Solutions</NavLink> */}
        {/* <NavLink href="#blog">Blog</NavLink> */}
        <NavLink href="#team">Team</NavLink>
        <NavLink href={ExplorerLink} target='_blank'>Explorer</NavLink>
        {/* <NavLink href={WalletLink} target='_blank'>Wallet</NavLink>
        <NavLink href={ExplorerLink} target='_blank'>Staking</NavLink> */}
        <ButtonContainer>
            <Button onClick={handleWhitePaper}>
                WhitePaper
            </Button>
            <Button onClick={handleLogin}>
                Login
            </Button>
      </ButtonContainer>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
