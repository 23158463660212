import React, { useState } from "react";
import { Divider, EmailInput, FooterContainer, FooterLinkContainer, FooterLinks, InputContainer, SubText, SubscribeButton } from "./Footer.styles";
import Logo from '../../assets/logo.png';
import { LogoContainer } from "../Header/Header.styles";

export default function Footer() {

    const [email, setEmail] = useState("");

    const handleChange = (event: any) => {

        setEmail(event.target.value);

    }

    const handleSubscribe = () => {

        console.log("email",email)

    }

    return (
        <FooterContainer>
            <LogoContainer>
                <img src={Logo} alt="logo" />
            </LogoContainer>
            {/* <SubText>
                Subscribe to get our Newsletter
            </SubText>
            <InputContainer>
                <EmailInput placeholder="Enter your email" onChange={handleChange} />
                <SubscribeButton onClick={handleSubscribe}>Subscribe</SubscribeButton>
            </InputContainer> */}
            <FooterLinkContainer>
                <div>
                    <FooterLinks href="#about" >
                        About
                        <Divider />
                    </FooterLinks>
                    {/* <FooterLinks href="#bar" >
                        Solutions
                        <Divider />
                    </FooterLinks> */}
                    <FooterLinks href="https://yvmexplorer.yatripay.com/" >
                        Explorer
                        <Divider />
                    </FooterLinks>
                    <FooterLinks href="#team" >
                        Team
                        <Divider />
                    </FooterLinks>
                </div>
                <div>
                    {/* <FooterLinks href="#blog" >
                        Blog
                        <Divider />
                    </FooterLinks> */}
                    {/* <FooterLinks target="_blank" href="https://wallet.yatripay.com/login" >
                        Blog
                        <Divider />
                    </FooterLinks> */}
                    <FooterLinks target="_blank" href="https://www.yatripay.com/asset/docs/privacy.pdf" >
                        Privacy Policy, Terms & Conditions
                    </FooterLinks>
                </div>
            </FooterLinkContainer>
            <SubText style={{ paddingTop: '40px' }}>Copyright © 2024 Yatri Gen Private Limited. All Rights Reserved</SubText>
        </FooterContainer>
    )
}