import styled from "styled-components";
import Vec1 from '../../../../assets/vec1.svg';
import Vec2 from '../../../../assets/vec2.svg';

export const Section = styled.section`
  padding: 120px 5%;
  display: flex;
  align-items: center;
  background-image: url(${Vec1}), url(${Vec2});
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right top, right bottom, center center;
  background-size: 50% 50%, 50% 50%, 80% 80%;;

  @media (max-width: 730px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextContainer = styled.div`
  max-width: 80%;
  text-align: left;
  flex: 0.9;

  @media (max-width: 600px) {
    text-align: center;
  }
`;


export const Button = styled.button`
    background-color: #C12276;
    color: #fff;
    border: none;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;

    @media (max-width: 850px) {
        margin: auto;
        display: block;
    }

    @media (max-width: 730px) {
        margin: auto;
        display: none;
    }
`;


export const ResponsiveButton = styled.button`
    background-color: #C12276;
    color: #fff;
    border: none;
    border-radius: 50px;
    box-sizing: border-box;
    padding: 12px 16px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    display: none;

    @media (max-width: 730px) {
        margin: auto;
        display: block;
    }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 60px;
  max-width: 20%;
  background: radial-gradient(circle at center, #FFD1DC 0%, rgba(255, 209, 220, 0) 80%);

  @media(max-width: 730px) {
    max-width: unset;
  }
`;

export const Card = styled.div`
  background: white;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CardIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
`;

export const CardTitle = styled.h3`
  color: #333;
  font-size: 1rem;
`;