import React from 'react';
import Varun from '../../../../assets/varun.png';
import Rishabh from '../../../../assets/Rishabh.jpeg';
import James from '../../../../assets/james.png';
import Atul from '../../../../assets/atul.png';
import { SectionContainer, SectionTitle, ResponsiveTeamList, TeamMemberCard, MemberImage, MemberName, MemberRole , ContentTitle, LinkedInIcon} from './TeamSection.styles';
import { Title, TitleHelper } from '../../../../common/Styles/Styles.styles';


// Defining types for team member data
type TeamMember = {
  name: string;
  role: string;
  imageUrl: string;
  linkedIn: string;
};

// Sample team member data
const teamMembers: TeamMember[] = [
  {
    name: 'Atul Kapoor',
    role: 'Co-Founder & CTO',
    imageUrl: Atul,
    linkedIn: "https://www.linkedin.com/in/atulkapoor"
  },
  {
    name: 'W James Bay',
    role: 'Co-Founder & CBO',
    imageUrl: James,
    linkedIn: "https://www.linkedin.com/in/w-james-bay"
  },  
  {
    name: 'Rishabh Shukla',
    role: 'Blockchain Advisor',
    imageUrl: Rishabh,
    linkedIn: "https://www.linkedin.com/in/rishabhworking"
  },
];

// React component
const TeamSection: React.FC = () => {
  return (
    <SectionContainer>
      <Title>Meet <TitleHelper>Our Team</TitleHelper></Title>
      <ResponsiveTeamList>
        {teamMembers.map((member) => (
          <TeamMemberCard key={member.name}>
            <MemberImage src={member.imageUrl} alt={member.name} />
            <MemberName>{member.name}</MemberName>
            <MemberRole>{member.role}</MemberRole>
            <LinkedInIcon href={member.linkedIn} target='_blank'>in</LinkedInIcon>
          </TeamMemberCard>
        ))}
      </ResponsiveTeamList>
    </SectionContainer>
  );
};

export default TeamSection;
