import { styled } from "styled-components";

export const ContentTitle = styled.div`
    font-size: 32px;
    font-weight: 500;
    display: block !important;
    line-height: 30px;
    letter-spacing: 0em;
    padding: 10px;
    text-align: center;
    color: #878787;
    margin-bottom: 30px;

    span {
      color: #D81B76;
    }

    @media (max-width: 800px) {
        font-size: 24px;  
    }
`;

export const ContentDescription = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    display: block;
    padding: 20px 0px;

    @media (max-width: 800px) {
        font-size: 16px;  
    }

`;

export const Title = styled.div`
    font-size: 60px;
    color: #000;
    font-weight: 600;
    // max-width: 800px;
    line-height: normal;

    @media(max-width: 850px) {
        font-size: 40px;
        text-align: center;
    }
`;

export const TitleHelper = styled.span`
    color: #AF1F72;
`;

export const SubTitle = styled.p`
    font-size: 20px;
    color: #666;
    max-width: 800px;
    margin: 20px 0;
    line-height: normal;
    font-weight: 400;

    @media(max-width: 850px) {
        text-align: center;
        margin: 10px 0;
    }
`;